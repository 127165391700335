import axios from 'axios'
import { Key } from './key';

const BASE_URL = 'https://api.password-pass.link/secrets'

export class KeyApiAdapter {

    
    async createKey() {
        const response = await axios.post(BASE_URL, { 'command': 'create' });
        return new Key(response.data.id, response.data.value);
    }

    async getKey(key_id, hmac) {
        const response = await axios.post(BASE_URL, { 'command': 'get', 'id': key_id, 'hmac': hmac});
        return new Key(response.data.id, response.data.value);
    }
}
