import React from 'react'
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'






const Footer = () => {
    return(
        <div>
        <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button color="inherit" component={Link} to="/privacy-policy">Privacy Policy</Button>
                <Button color="inherit" component={Link} to="/terms-and-conditions">Terms and Conditions</Button>
                <Button color="inherit" onClick={() => {window.displayPreferenceModal()}}>Cookie Preferences</Button>
            </Toolbar>
        </AppBar>
        </div>
    )
}

export default Footer;
